import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ExistingTenant,
  TenantStatus,
  createTenant,
  deleteTenant,
  deleteTenantUsers,
  demoteTenantUsers,
  getTenantDrift,
  getTenantUsers,
  getTenants,
  promoteTenantUsers,
  reprovisionTenant,
  updateTenant,
} from '~/api/tenant';

export const useTenants = (industryId?: string) => {
  const queryClient = useQueryClient();
  const { data, isLoading, isError, refetch, isSuccess, isFetched, isFetching, isRefetching } =
    useQuery(['industry', industryId || 'list', 'tenants'], getTenants(industryId), {
      initialData: [],
      onSuccess: (data) => {
        data.forEach((tenant) => {
          if (tenant.created && tenant.updated) {
            tenant.created =
              new Date(tenant.created).toLocaleDateString() +
              ' ' +
              new Date(tenant.created).toLocaleTimeString();
            tenant.updated =
              new Date(tenant.updated).toLocaleDateString() +
              ' ' +
              new Date(tenant.updated).toLocaleTimeString();
          }
          queryClient.setQueryData(
            ['industry', industryId || 'list', 'tenants', tenant.id],
            tenant,
          );
        });
      },
    });

  return {
    tenants: data,
    isLoading: isError || isLoading || !isSuccess || !isFetched,
    isFetching: isFetching || isRefetching,
    refetch,
  };
};

export const useTenantUsers = (tenant: ExistingTenant | undefined) => {
  const { data, isLoading, isError, refetch, isSuccess, isFetched, isFetching, isRefetching } =
    useQuery(
      ['industry', tenant?.industryId || 'list', 'tenants', tenant?.id, 'users'],
      getTenantUsers(tenant),
      {
        initialData: [],
        enabled: !!tenant,
        onSuccess: ({ data = [] }) => {
          data.forEach((user: any) => {
            if (user.created && user.updated) {
              user.created =
                new Date(user.created).toLocaleDateString() +
                ' ' +
                new Date(user.created).toLocaleTimeString();
              user.updated =
                new Date(user.updated).toLocaleDateString() +
                ' ' +
                new Date(user.updated).toLocaleTimeString();
            }
          });
          return data;
        },
      },
    );
  return {
    users: data?.users || [],
    isLoading: isError || isLoading || !isSuccess || !isFetched,
    isFetching: isFetching || isRefetching,
    refetch,
  };
};

export const useTenant = (industryId?: string, tenantId?: string) => {
  const { refetch } = useTenants(industryId);
  const queryClient = useQueryClient();
  const tenant = queryClient.getQueryData<ExistingTenant>([
    'industry',
    industryId || 'list',
    'tenants',
    tenantId,
  ]);

  return {
    tenant,
    isLoading: !tenant,
    refetch,
  };
};

export const useGetTenantDrift = (
  industryId?: string,
  tenantId?: string,
  tenantStatus?: TenantStatus,
) => {
  const { refetch } = useTenant(industryId, tenantId);
  const [driftDetected, setDriftDetected] = useState(undefined);
  const { isLoading } = useQuery(
    ['industry', industryId || 'list', 'tenants', tenantId, 'drift'],
    getTenantDrift(industryId, tenantId),
    {
      enabled:
        !!tenantId &&
        driftDetected === undefined &&
        tenantStatus &&
        tenantStatus === TenantStatus.ACTIVE,
      onSuccess: (data) => {
        setDriftDetected(data?.driftDetected);
        refetch();
      },
    },
  );

  return {
    driftDetected: driftDetected,
    isLoading,
  };
};

export const useMutateTenantUsers = (tenant: ExistingTenant | undefined) => {
  const {
    mutateAsync: promote,
    isLoading: isPromoteLoading,
    isError: isPromoteError,
    isSuccess: isPromoteSuccess,
  } = useMutation(
    ['tenant', tenant?.id, 'users'],
    promoteTenantUsers({ industryId: tenant?.industryId, tenantId: tenant?.id }),
  );
  const {
    mutateAsync: demote,
    isLoading: isDemoteLoading,
    isError: isDemoteError,
    isSuccess: isDemoteSuccess,
  } = useMutation(
    ['tenant', tenant?.id, 'users'],
    demoteTenantUsers({ industryId: tenant?.industryId, tenantId: tenant?.id }),
  );

  const {
    mutateAsync: remove,
    isLoading: isRemoveLoading,
    isError: isRemoveError,
    isSuccess: isRemoveSuccess,
  } = useMutation(
    ['tenant', tenant?.id, 'users'],
    deleteTenantUsers({ industryId: tenant?.industryId, tenantId: tenant?.id }),
  );
  return {
    promote,
    demote,
    remove,
    isLoading: isPromoteLoading || isDemoteLoading || isRemoveLoading || !tenant,
    isError: isPromoteError || isDemoteError || isRemoveError,
    isSuccess: isPromoteSuccess || isDemoteSuccess || isRemoveSuccess,
  };
};

export const useMutateTenant = () => {
  const {
    mutateAsync: create,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(['tenant'], createTenant);
  const {
    mutateAsync: update,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
  } = useMutation(['tenant'], updateTenant);
  const {
    mutateAsync: remove,
    isLoading: isRemoveLoading,
    isError: isRemoveError,
    isSuccess: isRemoveSuccess,
  } = useMutation(['tenant'], deleteTenant);
  const {
    mutateAsync: reprovision,
    isLoading: isReprovisionLoading,
    isError: isReprovisionError,
    isSuccess: isReprovisionSuccess,
  } = useMutation(['tenant'], reprovisionTenant);
  return {
    create,
    update,
    remove,
    reprovision,
    isLoading: isLoading || isUpdateLoading || isRemoveLoading || isReprovisionLoading,
    isError: isError || isUpdateError || isRemoveError || isReprovisionError,
    isSuccess: isSuccess || isUpdateSuccess || isRemoveSuccess || isReprovisionSuccess,
  };
};
