import React, { useMemo, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import MinusIcon from '@mui/icons-material/Remove';
import { Chip, Fab, Link } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tenant, TenantStatus, TenantStatusColor } from '~/api/tenant';
import DataGrid, { DataGridAction } from '~/elements/DataGrid';
import AddTenantDialog from '~/elements/Dialogs/AddTenantDialog';
import ConfirmDeleteDialog from '~/elements/Dialogs/ConfirmDeleteDialog';
import { useMutateTenant, useTenants } from '~/hooks/useTenants';

const Tenants: React.FC = () => {
  const tenantGridRef = useRef<any>(null);
  const params = useParams();
  const { tenants, refetch, isLoading, isFetching } = useTenants(params.industryId);

  const rows: GridRowsProp = useMemo(() => {
    if (!tenants.length) return [];
    return tenants.map((tenant: any) => ({
      id: tenant.id,
      name: tenant.name,
      subDomain: tenant.subDomain,
      status: tenant.status,
      contactEmail: tenant.contactEmail,
      created: `${new Date(tenant.created).toLocaleDateString()} ${new Date(
        tenant.created,
      ).toLocaleTimeString()}`,
      updated: `${new Date(tenant.updated).toLocaleDateString()} ${new Date(
        tenant.updated,
      ).toLocaleTimeString()}`,
    }));
  }, [tenants]);

  const columns = React.useMemo(
    (): GridColDef[] => [
      {
        field: 'id',
        headerName: 'ID',
        renderCell: (c) => (
          <Link component={RouterLink} to={`/industries/${params.industryId}/tenants/${c.value}`}>
            {c.value}
          </Link>
        ),
        flex: 1,
      },
      { field: 'name', headerName: 'Name', flex: 0.5 },
      { field: 'subDomain', headerName: 'Subdomain', flex: 0.5 },
      { field: 'contactEmail', headerName: 'Contact Email', flex: 1 },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.5,
        renderCell: (c) => (
          <Chip
            variant='outlined'
            label={c.value}
            color={TenantStatusColor[c.value as TenantStatus]}
          />
        ),
      },
      { field: 'created', headerName: 'Created', flex: 0.75 },
      { field: 'updated', headerName: 'Updated', flex: 0.75 },
    ],
    [params.industryId],
  );

  const [gridRows, setGridRows] = useState<GridRowsProp>([]);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [addTenantDialogOpen, setAddTenantDialogOpen] = useState(false);
  const actions = useMemo<DataGridAction[]>(
    () => [
      {
        key: 'refresh',
        label: <RefreshIcon />,
        onClick: refetch,
        alwaysEnabled: true,
        tooltip: 'Refresh Data',
      },
      {
        key: 'add',
        component: Fab,
        label: <AddIcon />,
        color: 'primary',
        onClick: (): void => {
          setAddTenantDialogOpen(true);
        },
        alwaysEnabled: true,
        tooltip: 'Add New Tenant',
      },
      {
        key: 'delete',
        label: <MinusIcon />,
        onClick: (): void => {
          setConfirmDeleteDialogOpen(true);
        },
        color: 'error',
        tooltip: 'Delete Tenant',
      },
    ],
    [refetch],
  );

  const reset = () => {
    setConfirmDeleteDialogOpen(false);
    refetch();
  };

  const { create, remove, isLoading: isTenantMutating } = useMutateTenant();

  const handleDeleteTenants = async () => {
    if (!gridRows.length || !params.industryId)
      return toast('Failed to delete tenant(s).', {
        type: 'error',
        toastId: 'delete-tenant-error',
      });
    try {
      const promises: Promise<any>[] = [];
      gridRows.forEach((row: any) => {
        promises.push(remove({ tenantId: row.id, industryId: params.industryId }));
      });
      await Promise.all(promises);
      toast('Tenant(s) deleted.', { type: 'success', toastId: 'delete-tenant-success' });
    } catch (err: any) {
      toast(`Failed to delete tenant(s): ${err.message}`, {
        type: 'error',
        toastId: 'delete-tenant-error',
      });
    } finally {
      reset();
    }
  };

  const handleAddTenant = async (data: Tenant) => {
    try {
      await create(data);
      toast('Tenant created.', { type: 'success', toastId: 'create-tenant-success' });
      setAddTenantDialogOpen(false);
    } catch (err: any) {
      toast(`Failed to create tenant: ${err.message}`, {
        type: 'error',
        toastId: 'create-tenant-error',
      });
    } finally {
      reset();
    }
  };

  return (
    <>
      <DataGrid
        gridTitle='Tenants'
        loading={isLoading || isFetching}
        actions={actions}
        ref={tenantGridRef}
        rows={rows}
        columns={columns}
        checkboxSelection={true}
        rowSelected={gridRows.length > 0}
        onRowSelectionModelChange={(ids: Iterable<unknown> | null | undefined) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = rows.filter((row) => selectedIDs.has(row.id));
          setGridRows(selectedRowData);
        }}
        sx={{ bgcolor: 'background.paper' }}
      />
      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialogOpen}
        isMutating={isTenantMutating}
        data={gridRows}
        onConfirm={handleDeleteTenants}
        onDeny={() => setConfirmDeleteDialogOpen(false)}
      />
      <AddTenantDialog
        onConfirm={handleAddTenant}
        isMutating={isTenantMutating}
        onDeny={() => setAddTenantDialogOpen(false)}
        isOpen={addTenantDialogOpen}
      />
    </>
  );
};

export default Tenants;
