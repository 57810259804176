import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import * as _ from 'lodash';
import { Link as RouterLink, useLocation } from 'react-router-dom';

export default function RouterBreadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <Breadcrumbs aria-label='breadcrumb' style={{ marginBottom: 10 }}>
      <Link component={RouterLink} underline='hover' color='inherit' to='/'>
        Home
      </Link>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color='text.primary' key={to}>
            {_.capitalize(value)}
          </Typography>
        ) : (
          <Link component={RouterLink} underline='hover' color='inherit' to={to} key={to}>
            {_.capitalize(value)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}
