import { useQuery, useMutation } from '@tanstack/react-query';
import { createIndustry, deleteIndustry, getIndustries, updateIndustry } from '~/api/industry';

export const useIndustries = () => {
  const { data, isLoading, isError, refetch, isSuccess, isFetched, isFetching, isRefetching } =
    useQuery(['industry', 'list'], getIndustries, {
      initialData: [],
    });

  return {
    industries: data,
    isLoading: isError || isLoading || !isSuccess || !isFetched,
    isFetching: isFetching || isRefetching,
    refetch,
  };
};

export const useMutateIndustry = () => {
  const {
    mutateAsync: create,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(['industry'], createIndustry);
  const {
    mutateAsync: update,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
  } = useMutation(['industry'], updateIndustry);
  const {
    mutateAsync: remove,
    isLoading: isRemoveLoading,
    isError: isRemoveError,
    isSuccess: isRemoveSuccess,
  } = useMutation(['industry'], deleteIndustry);
  return {
    create,
    update,
    remove,
    isLoading: isLoading || isUpdateLoading || isRemoveLoading,
    isError: isError || isUpdateError || isRemoveError,
    isSuccess: isSuccess || isUpdateSuccess || isRemoveSuccess,
  };
};
