import { useMemo, useState } from 'react';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';
import MinusIcon from '@mui/icons-material/Remove';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import { Fab } from '@mui/material';
import { Paper, Box, Typography, Button } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataGrid, { DataGridAction } from '~/elements/DataGrid';
import ConfirmDeleteDialog from '~/elements/Dialogs/ConfirmDeleteDialog';
import { useMutateTenantUsers, useTenant, useTenantUsers } from '~/hooks/useTenants';

const TenantUsers = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { tenant } = useTenant(params?.industryId, params?.tenantId);
  const { users, isLoading, isFetching, refetch } = useTenantUsers(tenant);
  const [gridRows, setGridRows] = useState<GridRowsProp>([]);

  const columns: GridColDef[] = [
    { field: 'fullName', headerName: 'User Name', flex: 0.75 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'tableauLicenseStatus', headerName: 'License Status', flex: 0.5 },
    { field: 'admin', headerName: 'Admin', flex: 0.5 },
    { field: 'lastLogin', headerName: 'Last Login Date', flex: 0.5 },
  ];
  const { promote, demote, remove, isLoading: isMutating } = useMutateTenantUsers(tenant);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const actions = useMemo<DataGridAction[]>(() => {
    const handlePromoteUser = async () => {
      const usersToPromote = gridRows.map((user) => user.id);
      try {
        await promote(usersToPromote);
        refetch();
      } catch (error) {
        const message = error instanceof Error ? error.message : 'Promote user failed.';
        toast(message, { toastId: 'promote-user-failed' });
      }
    };
    const handleDemoteUser = async () => {
      const usersToDemote = gridRows.map((user) => user.id);
      try {
        await demote(usersToDemote);
        refetch();
      } catch (error) {
        const message = error instanceof Error ? error.message : 'Demote user failed.';
        toast(message, { toastId: 'demote-user-failed' });
      }
    };

    const promoteDisabled = gridRows.some((user) => user.admin);
    const demoteDisabled = gridRows.some((user) => !user.admin);

    return [
      {
        key: 'refresh',
        alwaysEnabled: true,
        label: <RefreshIcon />,
        onClick: refetch,
        tooltip: 'Refresh Data',
      },
      {
        key: 'promote',
        color: 'primary',
        component: Fab,
        disabled: promoteDisabled,
        label: <AddModeratorIcon />,
        onClick: handlePromoteUser,
        tooltip: 'Promote User to Admin',
      },
      {
        key: 'demote',
        color: 'error',
        disabled: demoteDisabled,
        label: <RemoveModeratorIcon />,
        onClick: handleDemoteUser,
        tooltip: 'Demote User',
      },
      {
        key: 'delete',
        color: 'error',
        label: <MinusIcon />,
        onClick: (): void => {
          setConfirmDeleteDialogOpen(true);
        },
        tooltip: 'Delete User',
      },
    ];
  }, [demote, gridRows, promote, refetch]);

  const handleDeleteUsers = async () => {
    const userIdArrayFromMap: string[] = [];
    gridRows?.forEach((row) => {
      userIdArrayFromMap.push(row.email);
    });
    try {
      await remove(userIdArrayFromMap);
    } catch {
      console.log('error deleting users');
    }
  };
  return (
    <Paper sx={{ padding: 2, mb: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant='h6' justifyItems={'center'}>
            Tenant Users
          </Typography>
        </Box>
        <Box sx={{ ml: 'auto' }}>
          <Button
            variant='text'
            style={{ borderRadius: 0 }}
            size='small'
            startIcon={<ArrowBackIcon />}
            onClick={() =>
              navigate(`/industries/${params?.industryId}/tenants/${params?.tenantId}`)
            }>
            To Tenant Details
          </Button>
        </Box>
      </Box>
      <Box
        style={{
          paddingLeft: '0.5em',
          paddingTop: '1em',
        }}>
        <DataGrid
          loading={isLoading || isFetching}
          actions={actions}
          rows={users}
          columns={columns}
          checkboxSelection={true}
          rowSelected={gridRows.length > 0}
          onRowSelectionModelChange={(ids: Iterable<unknown> | null | undefined) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = users.filter((row: any) => selectedIDs.has(row.id));
            setGridRows(selectedRowData);
          }}
          sx={{ bgcolor: 'background.paper' }}
        />
      </Box>
      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialogOpen}
        isMutating={isMutating}
        data={gridRows}
        dataValue={'email'}
        onConfirm={handleDeleteUsers}
        onDeny={() => setConfirmDeleteDialogOpen(false)}
      />
    </Paper>
  );
};

export default TenantUsers;
