import Axios from 'axios';

export enum TenantStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  FAILED = 'FAILED',
  PROVISIONING = 'PROVISIONING',
  OUTDATED = 'OUTDATED',
}

export enum TenantStatusColor {
  NEW = 'default',
  ACTIVE = 'success',
  FAILED = 'error',
  PROVISIONING = 'info',
  OUTDATED = 'warning',
}

export interface Tenant {
  industryId: string;
  name: string;
  subDomain: string;
  endpoint: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  approvedEmailDomains: string[];
}

export interface ExistingTenant extends Tenant {
  id: string;
  allowSelfRegistration: boolean;
  authType: string;
  authDomain: string;
  resourceStackName: string;
  userPoolId: string;
  appClientId: string;
  status: TenantStatus;
  created: string;
  updated: string;
}

export const getTenants = (industryId?: string) => {
  return async () => {
    const { data } = await Axios.get<ExistingTenant[]>(
      `/industries${industryId ? `/${industryId}` : ''}/tenants`,
    );
    return data;
  };
};

export const getTenantUsers = (tenant: ExistingTenant | undefined) => {
  return async () => {
    const { data } = await Axios.get(
      `/industries/${tenant?.industryId}/tenants/${tenant?.id}/users`,
    );
    return data;
  };
};

export const createTenant = async (tenant: Tenant) => {
  const { data } = await Axios.post(`/industries/${tenant.industryId}/tenants`, tenant);
  return data;
};

export const updateTenant = async (tenant: ExistingTenant) => {
  const { data } = await Axios.patch(
    `/industries/${tenant.industryId}/tenants/${tenant.id}`,
    tenant,
  );
  return data;
};

export const deleteTenant = async ({
  tenantId,
  industryId,
}: {
  tenantId: string;
  industryId: string | undefined;
}) => {
  const { data } = await Axios.delete(`/industries/${industryId}/tenants/${tenantId}`);
  return data;
};

export const promoteTenantUsers = ({
  industryId,
  tenantId,
}: {
  industryId: string | undefined;
  tenantId: string | undefined;
}) => {
  return async (users: string[]) => {
    const promises = users.map(async (user) => {
      const { data } = await Axios.post(
        `/industries/${industryId}/tenants/${tenantId}/users/${user}/promote`,
      );
      return data;
    });

    return Promise.all(promises);
  };
};

export const demoteTenantUsers = ({
  industryId,
  tenantId,
}: {
  industryId: string | undefined;
  tenantId: string | undefined;
}) => {
  return async (users: string[]) => {
    const promises = users.map(async (user) => {
      const { data } = await Axios.post(
        `/industries/${industryId}/tenants/${tenantId}/users/${user}/demote`,
      );
      return data;
    });

    return Promise.all(promises);
  };
};

export const deleteTenantUsers = ({
  industryId,
  tenantId,
}: {
  industryId: string | undefined;
  tenantId: string | undefined;
}) => {
  return async (users: string[]) => {
    const { data } = await Axios.post(
      `/industries/${industryId}/tenants/${tenantId}/users/delete`,
      users,
    );

    return data;
  };
};

export const reprovisionTenant = async (tenantId: string) => {
  const { data } = await Axios.post(`/tenants/${tenantId}/reprovision`);
  return data;
};

export const getTenantDrift = (industryId?: string, tenantId?: string) => {
  return async () => {
    const { data } = await Axios.get(`/industries/${industryId}/tenants/${tenantId}/drift`);
    return data;
  };
};
