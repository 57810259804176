import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

export const useUser = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const userId = React.useMemo(
    () => JSON.parse(user?.attributes?.identities ?? '[]').at(0)?.userId,
    [user],
  );

  return {
    user,
    userId: userId,
    signOut,
  };
};
