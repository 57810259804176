import React from 'react';
import { useConfig } from '@kikoda/generated-config-hooks';
import { CircularProgress } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError, isAxiosError } from 'axios';
import { Navigate, Route, Routes } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { setAuthHeaders } from './api/auth';
import { useTokenRefresh } from './hooks/useTokenRefresh';
import { useUser } from './hooks/useUser';
import Layout from './Layout';
import Login from './Login';
import Industry from './pages/industries/[industryId]/Industry';
import Tenant from './pages/industries/[industryId]/tenants/[tenantId]/Tenant';
import TenantUsers from './pages/industries/[industryId]/tenants/[tenantId]/users';
import Industries from './pages/industries/Industries';
import { WebConfig } from './web-config';

const App = () => {
  const config = useConfig<WebConfig>();
  const { user } = useUser();
  const { currentToken } = useTokenRefresh();

  React.useEffect(() => {
    setAuthHeaders(currentToken);
  }, [currentToken]);

  const queryClient = React.useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 2,
            retryDelay: 1000,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: true,
            enabled: !!user,
            onError: (error: unknown) => {
              if (isAxiosError(error)) {
                toast((error as AxiosError).message, {
                  toastId: 'axios-error',
                });
              }
            },
          },
        },
      }),
    [user],
  );

  if (!config) {
    return <CircularProgress />;
  }

  if (!user) {
    return <Login />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path='/' element={<Navigate to='industries' />} />
          <Route path='/industries' element={<Industries />} />
          <Route path='/industries/:industryId' element={<Industry />} />
          <Route path='/industries/:industryId/tenants' element={<Industry />} />
          <Route path='/industries/:industryId/tenants/:tenantId' element={<Tenant />} />
          <Route path='/industries/:industryId/tenants/:tenantId/users' element={<TenantUsers />} />
        </Route>
      </Routes>
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={4000} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
