import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

const ConfirmDeleteDialog = ({
  isOpen,
  isMutating,
  data,
  onConfirm,
  onDeny,
  dataValue,
}: {
  isOpen: boolean;
  isMutating: boolean;
  data: readonly any[];
  onConfirm: () => void;
  onDeny: () => void;
  dataValue?: string;
}) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <Typography align='left' gutterBottom>
          This will permanently delete {data.length > 1 ? 'these' : 'this'} record
          {data.length > 1 ? 's' : ''}. This action cannot be undone.
        </Typography>
        <Box>
          {data.map((row, index) => (
            <Typography variant='h6' key={`${row.id}${index}`}>
              {`${row[`${dataValue || 'id'}`]}`}
            </Typography>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDeny}>Cancel</Button>
        <LoadingButton variant='contained' loading={isMutating} onClick={onConfirm}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
