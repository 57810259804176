import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Paper, Typography, Box, Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataAttribute from '~/elements/DataAttribute';
import { useIndustries, useMutateIndustry } from '~/hooks/useIndustries';
import Tenants from './Tenants';
import type { ExistingIndustry } from '~/api/industry';

const Industry: React.FC = () => {
  const navigate = useNavigate();
  const { industryId } = useParams();
  const { industries, refetch } = useIndustries();
  const industry = React.useMemo(
    () =>
      industries?.find((industry) => industry.id === industryId) ?? {
        id: '',
        name: '',
        description: '',
      },
    [industries, industryId],
  );

  const { register, handleSubmit } = useForm<ExistingIndustry>({
    mode: 'onTouched',
    defaultValues: {
      description: '',
    },
    values: {
      id: industry.id,
      name: industry.name,
      description: industry.description,
    },
  });

  const { update } = useMutateIndustry();
  const onSubmit = async (data: ExistingIndustry) => {
    try {
      await update({ ...data });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Industry update failed.';
      toast(`${message}`, { toastId: 'industry-update-failed' });
    } finally {
      refetch();
    }
  };
  const handleUpdate = handleSubmit(onSubmit);

  return (
    <>
      <Paper sx={{ mb: 2 }}>
        <Box sx={{ p: 2 }}>
          <form id='edit-industry-form' onSubmit={handleUpdate}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', pb: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  flexGrow: 1,
                }}>
                <Typography variant='h6' sx={{ pr: 2 }}>
                  Industry Details
                </Typography>
              </Box>
              <Box>
                <Button
                  variant='text'
                  style={{ borderRadius: 0 }}
                  size='small'
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate('/industries')}>
                  To Industries
                </Button>
              </Box>
            </Box>

            <Grid container>
              <Grid item xs={12} md={4} lg={3}>
                <DataAttribute label='Name' value={industry.name} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <DataAttribute label='ID' value={industry.id} />
              </Grid>
              <Grid item xs={12} md={8}>
                <DataAttribute
                  label='Description'
                  value={industry.description}
                  editable
                  copyable={false}
                  onSave={handleUpdate}
                  textFieldProps={{
                    ...register('description', {
                      required: 'Description is required.',
                    }),
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>

      <Paper>
        <Box sx={{ p: 2 }}>
          <Tenants />
        </Box>
      </Paper>
    </>
  );
};

export default Industry;
