import React from 'react';
import TrashIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { useParams } from 'react-router-dom';
import { Tenant } from '~/api/tenant';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='+1 (#00) 000-0000'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const AddTenantDialog = ({
  isOpen,
  isMutating,
  onConfirm,
  onDeny,
}: {
  isOpen: boolean;
  isMutating: boolean;
  defaultValue?: string;
  onConfirm: (tenant: Tenant) => void;
  onDeny: () => void;
}) => {
  const params = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Tenant>({
    mode: 'onTouched',
    defaultValues: {
      industryId: params.industryId,
      name: '',
      subDomain: '',
      contactFirstName: '',
      contactLastName: '',
      contactEmail: '',
      contactPhone: '',
      approvedEmailDomains: [],
    },
  });
  const [approvedDomains, setApprovedDomains] = React.useState<string[]>([]);
  const handleAddTenant = handleSubmit((data: Tenant) => {
    reset();
    onConfirm(data);
    setApprovedDomains([]);
  });
  const handleOnDeny = () => {
    reset();
    onDeny();
  };
  const handleAddApprovedDomain = () => {
    setApprovedDomains([...approvedDomains, '']);
  };
  const handleRemoveApprovedDomain = (index: number) => {
    setApprovedDomains(approvedDomains.filter((_, i) => i !== index));
  };

  return (
    <form id='add-tenant-form'>
      <Dialog open={isOpen}>
        <DialogTitle>Add Tenant</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12}>
              <TextField
                helperText='Please enter your new Tenant Name'
                label='Industry Id'
                disabled={true}
                {...register('industryId')}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                helperText='Please enter your new Tenant Name'
                label='Tenant Name'
                {...register('name', {
                  required: 'Name is required.',
                })}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                helperText='Please enter a description for this Tenant'
                label='Subdomain'
                {...register('subDomain', {
                  required: 'Subdomain is required.',
                })}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                helperText='Please enter a first name for this Contact'
                label='Contact First Name'
                {...register('contactFirstName', {
                  required: 'Contact First Name is required.',
                })}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                helperText='Please enter a last name for this Contact'
                label='Contact Last Name'
                {...register('contactLastName', {
                  required: 'Contact Last Name is required.',
                })}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                helperText='Please enter an email for this Contact'
                label='Contact Email'
                type='email'
                {...register('contactEmail', {
                  required: 'Contact Email is required.',
                })}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                helperText={
                  !!errors.contactPhone
                    ? 'Phone number must be a valid E.164 formatted phone number'
                    : 'Please enter a phone number for this Contact'
                }
                label='Contact Phone'
                type='string'
                {...register('contactPhone', {
                  required: 'Contact Phone is required.',
                  pattern: /^\+\d{1,3}\d{1,14}(\s\d{1,13})?/,
                  setValueAs: (value: string) => {
                    // Strip all non-numeric characters from phone number except for +
                    return value.replace(/[^0-9+]/g, '');
                  },
                })}
                InputProps={{
                  inputComponent: TextMaskCustom as any,
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                error={!!errors.contactPhone}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Button
                  sx={{ mb: 2, alignSelf: 'center' }}
                  variant='contained'
                  color='primary'
                  onClick={handleAddApprovedDomain}>
                  Add Approved Email Domain
                </Button>
                {approvedDomains.map((domain, index) => (
                  <Box
                    display={{ sm: 'flex' }}
                    alignContent={{ sm: 'center' }}
                    sx={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '100%',
                      p: 1,
                    }}>
                    <TextField
                      key={index}
                      helperText='Please enter an initial email domain for this tenant'
                      label='Approved Email Domain'
                      {...register(`approvedEmailDomains.${index}`, {
                        required: 'Approved Email Domain is required.',
                      })}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />

                    <IconButton
                      size='small'
                      aria-label='open drawer'
                      edge='start'
                      sx={{ ml: 2, borderRadius: 0 }}
                      color='error'
                      onClick={() => handleRemoveApprovedDomain(index)}>
                      <TrashIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnDeny}>Cancel</Button>
          <LoadingButton
            variant='contained'
            loading={isMutating}
            onClick={handleAddTenant}
            type='submit'>
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default AddTenantDialog;
