import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Box,
  IconButton,
  Skeleton,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';

// Create a Typography component with a copy to clipboard button that only shows when the text is hovered over
const CopyableText: React.FC<{ text: string }> = ({ text }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const textRef = React.useRef<HTMLSpanElement>(null);

  const handleCopy = () => {
    if (textRef.current) {
      navigator.clipboard.writeText(textRef.current.innerText);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  return (
    <Typography
      variant='body1'
      ref={textRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      {text}
      {isHovered && (
        <Tooltip title='Copy to clipboard' placement='bottom'>
          <IconButton size='small' onClick={handleCopy} sx={{ ml: 2, mt: -0.5 }}>
            {isCopied ? (
              <CheckIcon sx={{ fontSize: '0.8rem' }} />
            ) : (
              <FileCopyIcon sx={{ fontSize: '0.8rem' }} />
            )}
          </IconButton>
        </Tooltip>
      )}
    </Typography>
  );
};

export interface DataAttributeProps {
  label: string;
  value?: string;

  /**
   * Display a text field instead of the value
   *
   * @default false
   */
  editable?: boolean;

  /**
   * Callback when the text field is saved
   */
  onSave?: (...params: any) => void;

  /**
   * If you're using the editable field and not passing an `inputComponent, this
   * is the passthrough props for the default TextField
   */
  textFieldProps?: TextFieldProps;

  /**
   * If you're using the editable field and want to pass a custom input component
   * instead of the default TextField, you can pass it here
   */
  inputComponent?: React.ReactNode;

  /**
   * If you want to display a custom component instead of just the value, you can
   * pass it here
   */
  displayComponent?: React.ReactNode;

  /**
   * Remove bottom padding on the component
   *
   * @default true
   */
  gutterBottom?: boolean;

  /**
   * Disable the copy to clipboard button
   *
   * @default true
   */
  copyable?: boolean;
}

/**
 * DataAttribute is a component that displays a label and a value with
 * an optional copy to clipboard button. This is useful for displaying data from
 * an object. Use the `editable` prop to display a text field instead of the value.
 */
const DataAttribute: React.FC<DataAttributeProps> = ({
  label,
  value,
  editable = false,
  onSave,
  textFieldProps,
  inputComponent,
  displayComponent,
  gutterBottom = true,
  copyable = true,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [showEditButton, setShowEditButton] = React.useState(false);

  // set the text field to editable when double clicked and disabled when we click away
  return (
    <>
      {editable ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mt: 1,
          }}
          onMouseEnter={() => setShowEditButton(true)}
          onMouseLeave={() => setShowEditButton(false)}>
          {!isEditing && (
            <Box sx={gutterBottom ? { pb: 1 } : undefined}>
              <>
                <Typography
                  variant='caption'
                  color={(theme) => theme.palette.grey[600]}
                  sx={{ pb: 1 }}>
                  {label}
                </Typography>

                {value ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {displayComponent}
                    {!displayComponent &&
                      (copyable ? (
                        <CopyableText text={value} />
                      ) : (
                        <Typography variant='body1'>{value}</Typography>
                      ))}
                    {showEditButton && (
                      <Tooltip title='Edit'>
                        <IconButton size='small' onClick={() => setIsEditing(true)} sx={{ ml: 2 }}>
                          <EditIcon sx={{ fontSize: '0.8rem' }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                ) : (
                  <Skeleton variant='text' width='50%' />
                )}
              </>
            </Box>
          )}
          {inputComponent && isEditing && <>{inputComponent}</>}
          {!inputComponent && isEditing && (
            <TextField
              variant='standard'
              label={label}
              fullWidth
              disabled={!isEditing}
              InputLabelProps={{ shrink: true }}
              onDoubleClick={() => setIsEditing(true)}
              sx={{ mt: 1 }}
              {...textFieldProps}
            />
          )}
          {isEditing && (
            <Box sx={{ display: 'flex', ml: 2 }}>
              <Tooltip title='Save'>
                <IconButton
                  size='small'
                  onClick={() => {
                    setIsEditing(false);
                    onSave && onSave();
                  }}>
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Cancel'>
                <IconButton size='small' onClick={() => setIsEditing(false)}>
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={gutterBottom ? { pb: 1 } : undefined}>
          <>
            <Typography variant='caption' color={(theme) => theme.palette.grey[600]} sx={{ pb: 1 }}>
              {label}
            </Typography>

            {value ? (
              <>
                {displayComponent}
                {!displayComponent &&
                  (copyable ? (
                    <CopyableText text={value} />
                  ) : (
                    <Typography variant='body1'>{value}</Typography>
                  ))}
              </>
            ) : (
              <Skeleton variant='text' width='50%' />
            )}
          </>
        </Box>
      )}
    </>
  );
};

export default DataAttribute;
