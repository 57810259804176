import * as React from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Outlet } from 'react-router-dom';
import LochLogoDryIcon from '~/elements/Icons/LochLogoDry';
import LochNameIcon from '~/elements/Icons/LochName';
import RouterBreadcrumbs from './elements/RouterBreadcrumbs';
import { useUser } from './hooks/useUser';
import Logout from './Logout';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const RootBox = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  background: theme.palette.background.default,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const Layout: React.FC = () => {
  const { userId } = useUser();

  return (
    <RootBox>
      <CssBaseline />
      <AppBar position='fixed'>
        <Toolbar>
          <LochLogoDryIcon style={{ height: 44, paddingRight: 14 }} />
          <LochNameIcon style={{ height: 40, paddingRight: 28 }} />
          <Typography variant='h6' noWrap component='div'>
            Tenant Management
          </Typography>
          <Typography sx={{ position: 'absolute', right: 20 }}>
            {userId}
            <Logout />
          </Typography>
        </Toolbar>
      </AppBar>

      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        <RouterBreadcrumbs />

        <Outlet />
      </Box>
    </RootBox>
  );
};

export default Layout;
