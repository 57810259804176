import axios from 'axios';

export function setAuthHeaders(token: string) {
  // Do whatever necessary to set the Authorization header for web requests to the backend
  axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : undefined;

  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      if (!config.headers?.Authorization) {
        console.log('No Authorization header set');
        throw new Error('No Authorization header set');
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    },
  );
}
