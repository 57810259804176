import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { loadConfig } from '@kikoda/generated-config-hooks';
import { Provider as ConfigProvider } from '@kikoda/generated-config-hooks/lib/hooks/context';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Amplify } from 'aws-amplify';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import type { WebConfig } from './web-config';
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById('root');
const root = createRoot(container!);

const theme = createTheme({
  palette: {
    primary: {
      main: '#02516e',
    },
    secondary: {
      main: '#208399',
    },
    background: {
      default: '#e9e9e9',
    },
  },
});

async function render(host: string = '') {
  // Load config.
  const config = await loadConfig<WebConfig>(host);

  axios.defaults.baseURL = config.additionalConfig.platform.backend.endpoint;
  Amplify.configure({ Auth: config.additionalConfig.platform.auth });
  root.render(
    <React.StrictMode>
      <ConfigProvider value={{ config }}>
        <Authenticator.Provider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </BrowserRouter>
        </Authenticator.Provider>
      </ConfigProvider>
    </React.StrictMode>,
  );
}

// Async blocking render.
render().catch((error) => {
  console.error(error);
  root.render(<h1>Error</h1>);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
