import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { Industry } from '~/api/industry';

const AddIndustryDialog = ({
  isOpen,
  isMutating,
  onConfirm,
  onDeny,
}: {
  isOpen: boolean;
  isMutating: boolean;
  defaultValue?: string;
  onConfirm: (industry: Industry) => Promise<void>;
  onDeny: () => void;
}) => {
  const { register, handleSubmit, reset } = useForm<Industry>({
    mode: 'onTouched',
    defaultValues: {
      name: '',
      description: '',
    },
  });
  const handleAddIndustry = handleSubmit(async (data: Industry) => {
    await onConfirm(data);
    reset();
  });
  const handleOnDeny = () => {
    reset();
    onDeny();
  };
  return (
    <form id='add-industry-form'>
      <Dialog open={isOpen}>
        <DialogTitle>Add Industry</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12}>
              <TextField
                helperText='Please enter your new Industry Name'
                label='Industry Name'
                {...register('name', {
                  required: 'Name is required.',
                })}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                helperText='Please enter a description for this Industry'
                label='Description'
                {...register('description', {
                  required: 'Description is required.',
                })}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnDeny}>Cancel</Button>
          <LoadingButton
            variant='contained'
            loading={isMutating}
            onClick={handleAddIndustry}
            type='submit'>
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default AddIndustryDialog;
