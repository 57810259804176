import React from 'react';
import { useConfig } from '@kikoda/generated-config-hooks';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useSearchParams } from 'react-router-dom';
import LochLogoDryIcon from '~/elements/Icons/LochLogoDry';
import LochNameColorIcon from '~/elements/Icons/LochNameColor';
import { WebConfig } from './web-config';

const Login: React.FC = () => {
  const config = useConfig<WebConfig>();
  const [params] = useSearchParams();
  const [loading, setLoading] = React.useState(params.get('code') !== null);
  const theme = useTheme();

  React.useEffect(() => {
    if (params.get('code') !== null) {
      setLoading(true);
    }
  }, [params]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '60vh',
        textAlign: 'center',
      }}>
      <Paper elevation={2} sx={{ padding: 5 }}>
        <Typography variant='h6' sx={{ paddingBottom: 4, color: theme.palette.primary.main }}>
          <Box style={{ display: 'flex' }}>
            <LochLogoDryIcon style={{ paddingRight: 10, height: 100 }} />
            <LochNameColorIcon />
          </Box>
          Tenant Management
        </Typography>
        <LoadingButton
          loading={loading}
          variant='contained'
          color='secondary'
          endIcon={<VpnKeyOutlinedIcon />}
          onClick={() => {
            Auth.federatedSignIn({
              customProvider: config.additionalConfig.platform.identityProviderName,
            });
            setLoading(true);
          }}>
          Login with SSO
        </LoadingButton>
      </Paper>
    </Box>
  );
};

export default Login;
