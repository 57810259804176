import React, { useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import MinusIcon from '@mui/icons-material/Remove';
import { Fab, Link, Paper } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Industry } from '~/api/industry';
import DataGrid, { DataGridAction } from '~/elements/DataGrid';
import AddIndustryDialog from '~/elements/Dialogs/AddIndustryDialog';
import ConfirmDeleteDialog from '~/elements/Dialogs/ConfirmDeleteDialog';
import { useIndustries, useMutateIndustry } from '~/hooks/useIndustries';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    renderCell: (c) => (
      <Link component={RouterLink} to={`/industries/${c.value}`}>
        {c.value}
      </Link>
    ),
    flex: 1,
  },
  { field: 'name', headerName: 'Name', flex: 0.5 },
  { field: 'description', headerName: 'Description', flex: 1 },
];

const Industries: React.FC = () => {
  const [industry, setIndustry] = useState<
    { id: string; industryId: string; name: string } | undefined
  >();
  const { industries, refetch, isLoading, isFetching } = useIndustries();

  const rows: GridRowsProp = useMemo(() => {
    if (!industries) return [];
    return industries.map((industry: any) => ({
      id: industry.id,
      name: industry.name,
      description: industry.description,
    }));
  }, [industries]);

  const [addIndustryDialogOpen, setAddIndustryDialogOpen] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const actions = useMemo<DataGridAction[]>(() => {
    return [
      {
        key: 'refresh',
        label: <RefreshIcon />,
        onClick: refetch,
        alwaysEnabled: true,
        tooltip: 'Refresh Data',
      },
      {
        key: 'add',
        component: Fab,
        color: 'primary',
        label: <AddIcon />,
        onClick: (): void => {
          setAddIndustryDialogOpen(true);
        },
        alwaysEnabled: true,
        tooltip: 'Add New Industry',
      },
      {
        key: 'delete',
        label: <MinusIcon />,
        onClick: (): void => {
          setConfirmDeleteDialogOpen(true);
        },
        color: 'error',
        tooltip: 'Delete Industry',
      },
    ];
  }, [refetch]);

  const reset = () => {
    setAddIndustryDialogOpen(false);
    setConfirmDeleteDialogOpen(false);
    refetch();
  };

  const { create, remove, isLoading: isMutating } = useMutateIndustry();

  const handleDeleteIndustry = async () => {
    if (!industry) return;
    try {
      await remove(industry.id);
      console.debug('industry deleted');
    } catch (error: any) {
      toast.error(`Delete Industry Failed: ${error.message}`, { toastId: 'delete-industry-error' });
    } finally {
      reset();
    }
  };

  const handleAddIndustry = async (newIndustry: Industry) => {
    try {
      await create(newIndustry);
      toast.success(`Industry added!`, { toastId: 'add-industry-success' });
    } catch (error: any) {
      toast.error(`Add Industry Failed: ${error.message}`, { toastId: 'add-industry-error' });
    } finally {
      reset();
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      <DataGrid
        loading={isLoading || isFetching || isMutating}
        gridTitle='Industries'
        actions={actions}
        rows={rows}
        columns={columns}
        rowSelected={industry === undefined ? false : true}
        checkboxSelection={true}
        onRowSelectionModelChange={(ids: Iterable<unknown> | null | undefined) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = rows.filter((row) => selectedIDs.has(row.id));
          if (selectedRowData.length === 0) return;
          setIndustry({
            id: selectedRowData?.at(0)?.id,
            name: selectedRowData?.at(0)?.name,
            industryId: selectedRowData?.at(0)?.industryId,
          });
        }}
        hideFooterSelectedRowCount={true}
        sx={{ bgcolor: 'background.paper' }}
      />
      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialogOpen}
        isMutating={isMutating}
        data={[{ id: industry?.name }]}
        onConfirm={handleDeleteIndustry}
        onDeny={reset}
      />
      <AddIndustryDialog
        isOpen={addIndustryDialogOpen}
        isMutating={isMutating}
        onConfirm={handleAddIndustry}
        onDeny={reset}
      />
    </Paper>
  );
};

export default Industries;
