import Axios from 'axios';

export interface Industry {
  name: string;
  description?: string;
}

export interface ExistingIndustry extends Industry {
  id: string;
}

export interface UpdatedIndustry {
  id: string;
  description?: string;
}

export const getIndustries = async () => {
  const { data } = await Axios.get<ExistingIndustry[]>('/industries');
  return data;
};

export const createIndustry = async (industry: Industry) => {
  const { data } = await Axios.post('/industries', industry);
  return data;
};

export const updateIndustry = async (industry: UpdatedIndustry) => {
  const { data } = await Axios.patch(`/industries/${industry.id}`, industry);
  return data;
};

export const deleteIndustry = async (industryId: string) => {
  const { data } = await Axios.delete(`/industries/${industryId}`);
  return data;
};
