import React from 'react';
import { Auth } from 'aws-amplify';

/**
 * This hook will refresh the token when it is expired. It will also call a
 * setup function when the token is refreshed. This is useful for doing things
 * like setting the Authorization header on axios requests.
 *
 * @returns The current token and the expiration time of the token.
 *
 * @example
 * ```tsx
 * const { currentToken, expirationTime } = useTokenRefresh();
 *
 * // Set the Authorization header on axios requests
 * React.useEffect(() => {
 *  axios.defaults.headers.common['Authorization'] = currentToken;
 * }, [currentToken]);
 * ```
 */
export const useTokenRefresh = () => {
  const [currentToken, setCurrentToken] = React.useState<string>('');
  const [expirationTime, setExpirationTime] = React.useState<number>(0);

  const refreshToken = React.useCallback((expirationTime: number) => {
    console.debug('scheduling token refresh', new Date(expirationTime * 1000));
    const intervalIdentifier = setInterval(() => {
      const getSession = async () => {
        try {
          console.debug('Trying to refresh token');

          // Calling Auth.currentSession() will refresh the token if it is expired
          const session = await Auth.currentSession();
          const newToken = session.getIdToken().getJwtToken();
          setCurrentToken(newToken);

          // Clear the current interval
          clearInterval(intervalIdentifier);

          /**
           * Reset the trigger for the difference in
           * time from token expiration to current
           */
          const expirationTime = session.getIdToken().getExpiration();
          setExpirationTime(expirationTime);
          refreshToken(expirationTime);
        } catch (error) {
          console.error('Error refreshing token: ', error);
        }
      };

      getSession();
    }, new Date(expirationTime * 1000).getTime() - new Date().getTime());
  }, []);

  React.useEffect(() => {
    const getSession = async () => {
      const session = await Auth.currentSession();
      const expirationTime = session.getIdToken().getExpiration();
      const token = session.getIdToken().getJwtToken();

      setCurrentToken(token);
      setExpirationTime(expirationTime);
      refreshToken(expirationTime);
    };

    getSession();
  }, [refreshToken]);

  return {
    currentToken,
    expirationTime,
  };
};
