import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UsersIcon from '@mui/icons-material/People';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  Grid,
  Paper,
  Link,
} from '@mui/material';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ExistingTenant, TenantStatus, TenantStatusColor } from '~/api/tenant';
import DataAttribute from '~/elements/DataAttribute';
import { useGetTenantDrift, useMutateTenant, useTenant } from '~/hooks/useTenants';

const Tenant: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { tenant, refetch } = useTenant(params?.industryId, params?.tenantId);
  const { driftDetected } = useGetTenantDrift(params?.industryId, params?.tenantId, tenant?.status);

  const { register, handleSubmit } = useForm<ExistingTenant>({ mode: 'onTouched', values: tenant });
  const { reprovision, update } = useMutateTenant();

  const handleReprovisionClick = async () => {
    try {
      if (!params?.tenantId) return;
      await reprovision(params?.tenantId);
      refetch();
    } catch (error: any) {
      toast.error(error?.message, {
        toastId: 'tenant-reprovision-failed',
      });
    }
  };

  const onSubmit = async (data: ExistingTenant) => {
    try {
      await update({ ...data });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Tenant update failed.';
      toast(`${message}`, { toastId: 'tenant-update-failed' });
    } finally {
      refetch();
    }
  };
  const handleUpdate = handleSubmit(onSubmit);

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant='h6' justifyItems={'center'}>
              Tenant Details
              <Chip
                variant='outlined'
                label={tenant?.status}
                sx={{ ml: 2, mr: 1 }}
                color={TenantStatusColor[tenant?.status as TenantStatus]}
              />
            </Typography>
          </Box>
          <Box sx={{ ml: 'auto' }}>
            <Button
              variant='text'
              style={{ borderRadius: 0 }}
              size='small'
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(`/industries/${params?.industryId}`)}>
              To Tenants
            </Button>
            <Button
              variant='contained'
              color='primary'
              size='small'
              style={{ marginLeft: '1em' }}
              startIcon={<UsersIcon />}
              onClick={() => navigate('./users')}>
              Users
            </Button>
            <LoadingButton
              variant='contained'
              color='primary'
              size='small'
              style={{ marginLeft: '1em' }}
              startIcon={<RefreshIcon />}
              disabled={driftDetected === undefined || tenant?.status !== TenantStatus.OUTDATED}
              onClick={() => handleReprovisionClick()}
              loading={driftDetected === undefined}
              loadingPosition='start'>
              <span>
                {driftDetected === undefined
                  ? 'Detecting Drift'
                  : driftDetected === true || tenant?.status === TenantStatus.OUTDATED
                  ? 'Reprovision Tenant'
                  : 'Tenant Provisioned'}
              </span>
            </LoadingButton>
          </Box>
        </Box>
        <Box sx={{ pl: 1, justifyContent: 'flex-start' }}>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid item xs={12} md={6}>
                  <DataAttribute label='Name' value={tenant?.name} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DataAttribute label='Sub Domain' value={tenant?.subDomain} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DataAttribute
                    label='Endpoint'
                    value={tenant?.endpoint}
                    displayComponent={
                      <Typography variant='body1'>
                        <Link target='_blank' href={tenant?.endpoint}>
                          {tenant?.endpoint}
                        </Link>
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                  <DataAttribute
                    label='Created'
                    value={tenant?.created ? moment(tenant.created).format('LLL') : undefined}
                    copyable={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataAttribute
                    label='Updated'
                    value={tenant?.updated ? moment(tenant.updated).format('LLL') : undefined}
                    copyable={false}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                  <DataAttribute label='ID' value={tenant?.id} />
                </Grid>
                <Grid item xs={12}>
                  <DataAttribute label='Industry Id' value={tenant?.industryId} />
                </Grid>
                <Grid item xs={12}>
                  <DataAttribute label='Resource Stack Name' value={tenant?.resourceStackName} />
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} md={6}>
                <DataAttribute
                  label='Owner Name'
                  value={
                    tenant ? `${tenant.contactFirstName} ${tenant.contactLastName}` : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DataAttribute label='Owner Email' value={tenant?.contactEmail} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>

      <Accordion sx={{ mt: 2 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Advanced</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DataAttribute
                label='Approved Domains'
                value={tenant?.approvedEmailDomains?.join(', ')}
                copyable={false}
                editable
                onSave={handleUpdate}
                textFieldProps={{
                  ...register('approvedEmailDomains', {
                    /**
                     * `setValueAs` is not typed in react-hook-form
                     * eg. `setValueAs: (value: any) => any`
                     *
                     * so we have to do some manual type checking here.
                     */
                    setValueAs: (value) => {
                      if (typeof value !== 'string' && !Array.isArray(value)) {
                        return value;
                      }
                      const array = typeof value === 'string' ? value.split(',') : value;
                      return array.map((v) => (typeof v === 'string' ? v.trim() : v));
                    },
                  }),
                  helperText: 'Comma separated list of approved email domains',
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DataAttribute
                label='Allow Self Registration'
                value={tenant?.allowSelfRegistration ? 'Yes' : 'No'}
                copyable={false}
                editable
                onSave={handleUpdate}
                inputComponent={
                  <>
                    <Checkbox
                      defaultChecked={tenant?.allowSelfRegistration}
                      {...register('allowSelfRegistration')}
                      color='primary'
                      size='small'
                    />
                    <Typography variant='body1'>
                      Allow users to register themselves without approval
                    </Typography>
                  </>
                }
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Tenant;
