import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton, Tooltip } from '@mui/material';

const Logout: React.FC = () => {
  const { signOut } = useAuthenticator((context) => [context.signOut]);
  return (
    <Tooltip title='Logout'>
      <IconButton sx={{ marginLeft: 2 }} color='inherit' onClick={signOut}>
        <LogoutIcon />
      </IconButton>
    </Tooltip>
  );
};

export default Logout;
